.home{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
}

.introduction{
    color: var(--txt-clr);
    height: 60%;
    width: 40%;
}

.introduction h1{
    font-size: 4.5vw;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: 10vh;
    font-weight: 600 ;
}

.introduction p{
    font-size: 1.6vw;
    font-family:'Courier New', Courier, monospace;
    font-weight: 700;
    line-height: 4vh;
}

.introduction button{
    font-family:monospace;
    font-size: 1.3vw;
    border: none;
    padding: 0.6vw 1.5vw;
    background-color: var(--dot-bg);
    border-radius: 20px;
    transition: ease-in-out 0.1s;
}

.introduction button:hover{
    cursor: pointer;
    background-color: var(--intro-btn-hovr);
}

.coder-image{
    display: flex;
    align-items: center;
    height: 90%;
    width: 40%;
    animation: infinite animat 4.5s;
}

.coder-image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@keyframes animat {
    0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(20px);
      }
}

@media (max-width:768px) {
    .home{
        flex-direction: column-reverse;
        justify-content:flex-end;
        gap: 20px;
    }

    .introduction{
        width: 80%;
        height: 40%;
    }

    .introduction h1{
        font-size: 4.5vh;
        line-height: 4vh;
    }
    .introduction p{
        font-size: 2vh;
        line-height: 3vh;
    }

    .introduction button{
        font-size: 2.5vw;
        padding: 1vw 2vw;
    }

    .coder-image{
        height: 50%;
        width: 70%;
    }
}


@media (max-width:600px){
    .home{
       gap: 30px;
    }

    .introduction{
        font-size: 3.4vw;
    }

    .introduction button{
        font-size: 5vw;
        padding: 2vw 3.5vw;
    }

    .coder-image{
        height: 40%;
        animation: none;
    }
}