.contact{
    margin-top: 4vh;
    width: 70vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid violet; */
    box-shadow: 0 8px 30px 0 var(--card-shadow);
    border: 2px solid var(--form-border);
    border-radius: 10px;
    flex-direction: column;
    padding: 25px;
}

.contact h1{
    color: var(--txt-clr);
}

.form{
    box-sizing: border-box;
    padding: 20px 10px;
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;  
}

input{
    color: var(--txt-clr);
    box-sizing: border-box;
    padding-left: 10px;
    height: 7vh;
    width: 30vw;
    border-radius: 10px;
    outline: none;
    border: 3px solid var(--form-border);
    transition: ease-in-out 0.5s;
    background-color: transparent;
}

input:focus{
    border: 3px solid var(--focus-clr);
}

.message-box{
    color: var(--txt-clr);
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    height: 30vh;
    width: 61vw;
    resize: none;
    border-radius: 10px;
    border: 3px solid var(--form-border);
    outline: none;
    transition: ease-in-out 0.5s;
    background-color: transparent;
}

.message-box:focus{
    border: 3px solid var(--focus-clr);
}

.btn{
    height: 6vh;
    width: 10vw;
    border-radius: 10px;
    outline: none;
    background-color: transparent;
    border: 2px solid var(--focus-clr);
    transition: ease-in-out 0.3s;
    font-weight: bold;
    color: var(--txt-clr);
}

.btn:hover{
    background-color: var(--focus-clr);
    color: var(--btn-txt-hover);
}

@media (max-width:768px) {
    .contact{
        margin-top: 3vh;
        width: 80vw;
    }

    .form{
        width: 100%;
        flex-direction: column;
    }

    input{
        width: 100%;
    }

    .message-box{
        width: 100%;
    }

    .btn{
        width: 25vw;
        background-color: var(--focus-clr);
        color: var(--btn-txt-hover);
    }
}