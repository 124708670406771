.nav{
    height: 14vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 30px;
    background-color: transparent;
    backdrop-filter: blur(30px);
    z-index: 99;
    transition: all ease-in-out 0.7s;
}

.logo{
    margin-left: 30px;
    color: var(--focus-clr);
}
  
.logo span{
    color: var(--txt-clr);
}

.page-links{
    display: flex;
    justify-content: space-between;
    margin-right: 5%;
}

.page-links .link{
    margin: 0px 10px;
    font-size: 20px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 500;
    color: var(--txt-clr);
    transition: 0.3s ease;
}

.page-links .link:hover{
    background-color: var(--selection-clr);
    transition: 0.3s ease;
    /* cursor: pointer; */
}

.move-to-top{
    position: fixed;
    z-index: 100;
    top: 90%;
    left: 95%;
}

.nav-btn{
    display: none;
}

.hidden{
    overflow: hidden;
    position: absolute;
    top: 12vh;
    left: 50%;
    transform: translate(-50%);
    width: 0px;
    transition: ease-in-out 0.3s;
}

.theme-toggle{
    color: var(--txt-clr);
    display: flex;
    justify-content: center;
    align-items: center;
}

.theme-toggle:hover{
    cursor: pointer;
}

@media (max-width:768px) {
    .page-links{
        display: none;
    }
    
    .nav{
        position: fixed ;
        top: 0px;
        /* background-color: black; */
        width: 90%;
    }

    .logo{
        font-size: 13px;
        margin-left: 0px;
    }

    .nav-btn{
        position: fixed;
        right: 12vw;
        display: block;
    }

    .secret{
        position: absolute;
        /* display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; */
        top: 12vh;
        left: 50%;
        transform: translate(-53%);
        padding: 2vh;
        width: 80%;
        background-color: var(--mobile-nav);
        z-index: 99;
        border: 2px solid var(--mobile-nav-border);
        border-radius: 10px;
        display: block;
        /* transition: ease-in-out 0.3s; */
    }

    .link{
        margin: 3vh 0vh;
        color: var(--mobile-nav-clr);
        font-size: 2.3vh;
    }
}