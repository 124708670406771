.socials{
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.social-card:hover{
    cursor: pointer;
}

#darshan{
    font-size: 10vh;
}

@media (max-width:768px){
    .socials{
        gap: 2vh;
    }
}