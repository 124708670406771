.projects{
    height: 100%;
    width: 90%;
    align-items: center;
    /* border: 2px solid violet; */
    display: flex;
    flex-wrap: wrap;
    gap: 4vw;
    align-items: center;
    justify-content: space-evenly;
}

.project-card{
    color: var(--txt-clr);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    padding: 15px;
    /* box-sizing: border-box; */
    margin-top: 30px;
    height: 60vh;
    width: 21vw;
    border:1px solid var(--card-border);
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 var(--card-shadow);
    transition: ease-in-out 0.25s;
}

.project-card:hover{
    box-shadow:var(--card-shadow-hvr0) , var(--card-shadow-hvr1) , var(--card-shadow-hvr2) , var(--card-shadow-hvr3);
}

.project-img{
    height: 25vh;
    width: 20vw;
    overflow: hidden;
    border-radius: 10px;
}

.project-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width:768px) {
    .project-card{
        width: 35vw;
    }

    .project-img{
        height: 20vh;
        width: 35vw;
    }
}

@media (max-width:600px) {
    .project-card{
        width: 60vw;
        height: 35vh;
    }

    .project-card p{
        font-size: 2.5vw;
    }

    .project-img{
        height: 15vh;
        width: 60vw;
    }
}