.skills{
    margin-top: 7vh;
    height: 100%;
    width: 80%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
    /* border: 2px solid violet; */
}

.skill-card{
    background-color: var(--skill-card-bg);
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 10px;
    height: 10vh;
    width: 15vw;
    box-shadow: 0 8px 32px 0 var(--card-shadow);
    border: 2px solid var(--card-border);
    border-radius: 10px;
    transition: ease-in-out 0.1s;
}

.skill-card2{
    background-color: var(--skill-card-bg);
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 10px;
    height: 10vh;
    width: 15vw;
    box-shadow: 0 8px 32px 0 var(--card-shadow);
    border: 2px solid var(--card-border);
    border-radius: 10px;
    transition: ease-in-out 0.1s;

    .skill-name{
        font-size: 1vw;
    }

    &:hover{
        box-shadow: 0px 0px 5px var(--selection-clr) , 0px 0px 15px var(--selection-clr) , 0px 0px 30px var(--focus-clr) , 0px 0px 50px var(--selection-clr);
    }
}

.skill-card:hover{
    box-shadow: 0px 0px 5px var(--selection-clr) , 0px 0px 15px var(--selection-clr) , 0px 0px 30px var(--focus-clr) , 0px 0px 50px var(--selection-clr);
}

.skill-name{
    color: var(--txt-clr);
    font-size: 1.3vw;
    font-weight: 400;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width:768px) {
    .skill-card{
        width: 30vw;
        margin-top: 10px
    }

    .skill-name{
        font-size: 2vw;
    }
}

@media (max-width:600px) {
    .skill-card{
        width: 70vw;
        margin-top: 10px;
    }

    .skill-card2{
        width: 70vw;
        margin-top: 10px;
        .skill-name{
            font-size: 4vw;
        }
    }

    .skill-name{
        font-size: 4vw;
    }

    .skill-card{
        padding: 10px 50px;
        gap: 20px;
    }

    .skills{
        margin-top: 2vh;
    }
}