.custom-cursor{
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 999;
    mix-blend-mode:var(--mode);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgba(254, 141, 89); */
    background-color: var(--cursor-bg);
}

.dot{
    position: fixed;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 999;
    /* mix-blend-mode: difference; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dot-bg);
}

.cursor-text{
    display: none; 
    font-size: 3px; 
    background-color: transparent;
    font-weight: 400;
}

@media (max-width: 768px) {
    .custom-cursor{
        display: none;
    }

    .cursor-text{
        display: none;
    }

    .dot{
        display: none;
    }
}